import { format, parseISO } from 'date-fns'
import { get } from 'lodash-es'

export type Option = {
  value: string
  label: string
  icon?: string
  avatar?: { src: string, alt?: string }
  color?: string
  disabled?: boolean
  className?: string
  [k: string]: unknown // extra properties e.g. description
}

// options:

export const OPTIONS_DELETED_AT: Option[] = [
  {
    value: 'true',
    label: 'Archivado',
    icon: 'i-mdi-archive',
    color: 'gray',
  },
  {
    value: 'false',
    label: 'Desarchivado',
    icon: 'i-mdi-archive-off',
    color: 'gray',
  },
]

// from https://gist.github.com/keeguon/2310008?permalink_comment_id=4970109#gistcomment-4970109
export const OPTIONS_COUNTRY_CODE: Option[] = [
  { value: 'MX', label: 'México' },
  { value: 'US', label: 'Estados Unidos' },
  { value: 'AF', label: 'Afganistán' },
  { value: 'AX', label: 'Islas Åland' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Argelia' },
  { value: 'AS', label: 'Samoa Americana' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguila' },
  { value: 'AQ', label: 'Antártida' },
  { value: 'AG', label: 'Antigua y Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaiyán' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Baréin' },
  { value: 'BD', label: 'Bangladés' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Bielorrusia' },
  { value: 'BE', label: 'Bélgica' },
  { value: 'BZ', label: 'Belice' },
  { value: 'BJ', label: 'Benín' },
  { value: 'BM', label: 'Bermudas' },
  { value: 'BT', label: 'Bután' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BA', label: 'Bosnia y Herzegovina' },
  { value: 'BW', label: 'Botsuana' },
  { value: 'BV', label: 'Isla Bouvet' },
  { value: 'BR', label: 'Brasil' },
  { value: 'IO', label: 'Territorio Británico del Océano Índico' },
  { value: 'BN', label: 'Brunéi' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Camboya' },
  { value: 'CM', label: 'Camerún' },
  { value: 'CA', label: 'Canadá' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'KY', label: 'Islas Caimán' },
  { value: 'CF', label: 'República Centroafricana' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Isla de Navidad' },
  { value: 'CC', label: 'Islas Cocos' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoras' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'República Democrática del Congo' },
  { value: 'CK', label: 'Islas Cook' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: 'Costa de Marfil' },
  { value: 'HR', label: 'Croacia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CY', label: 'Chipre' },
  { value: 'CZ', label: 'República Checa' },
  { value: 'DK', label: 'Dinamarca' },
  { value: 'DJ', label: 'Yibuti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'República Dominicana' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egipto' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Guinea Ecuatorial' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Etiopía' },
  { value: 'FK', label: 'Islas Malvinas' },
  { value: 'FO', label: 'Islas Feroe' },
  { value: 'FJ', label: 'Fiyi' },
  { value: 'FI', label: 'Finlandia' },
  { value: 'FR', label: 'Francia' },
  { value: 'GF', label: 'Guayana Francesa' },
  { value: 'PF', label: 'Polinesia Francesa' },
  { value: 'TF', label: 'Territorios Australes Franceses' },
  { value: 'GA', label: 'Gabón' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Alemania' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Grecia' },
  { value: 'GL', label: 'Groenlandia' },
  { value: 'GD', label: 'Granada' },
  { value: 'GP', label: 'Guadalupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haití' },
  { value: 'HM', label: 'Islas Heard y McDonald' },
  { value: 'VA', label: 'Santa Sede (Ciudad del Vaticano)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungría' },
  { value: 'IS', label: 'Islandia' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Irán' },
  { value: 'IQ', label: 'Irak' },
  { value: 'IE', label: 'Irlanda' },
  { value: 'IM', label: 'Isla de Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italia' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japón' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordania' },
  { value: 'KZ', label: 'Kazajistán' },
  { value: 'KE', label: 'Kenia' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: 'Corea del Norte' },
  { value: 'KR', label: 'Corea del Sur' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kirguistán' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Letonia' },
  { value: 'LB', label: 'Líbano' },
  { value: 'LS', label: 'Lesoto' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libia' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lituania' },
  { value: 'LU', label: 'Luxemburgo' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malaui' },
  { value: 'MY', label: 'Malasia' },
  { value: 'MV', label: 'Maldivas' },
  { value: 'ML', label: 'Malí' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Islas Marshall' },
  { value: 'MQ', label: 'Martinica' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauricio' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'FM', label: 'Micronesia' },
  { value: 'MD', label: 'Moldavia' },
  { value: 'MC', label: 'Mónaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Marruecos' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Birmania' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Países Bajos' },
  { value: 'AN', label: 'Antillas Neerlandesas' },
  { value: 'NC', label: 'Nueva Caledonia' },
  { value: 'NZ', label: 'Nueva Zelanda' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Níger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Isla Norfolk' },
  { value: 'MP', label: 'Islas Marianas del Norte' },
  { value: 'NO', label: 'Noruega' },
  { value: 'OM', label: 'Omán' },
  { value: 'PK', label: 'Pakistán' },
  { value: 'PW', label: 'Palaos' },
  { value: 'PS', label: 'Palestina' },
  { value: 'PA', label: 'Panamá' },
  { value: 'PG', label: 'Papúa Nueva Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Perú' },
  { value: 'PH', label: 'Filipinas' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Polonia' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Catar' },
  { value: 'RE', label: 'Reunión' },
  { value: 'RO', label: 'Rumania' },
  { value: 'RU', label: 'Rusia' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'SH', label: 'Santa Helena' },
  { value: 'KN', label: 'San Cristóbal y Nieves' },
  { value: 'LC', label: 'Santa Lucía' },
  { value: 'PM', label: 'San Pedro y Miquelón' },
  { value: 'VC', label: 'San Vicente y las Granadinas' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Santo Tomé y Príncipe' },
  { value: 'SA', label: 'Arabia Saudita' },
  { value: 'SN', label: 'Senegal' },
  { value: 'CS', label: 'Serbia y Montenegro' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leona' },
  { value: 'SG', label: 'Singapur' },
  { value: 'SK', label: 'Eslovaquia' },
  { value: 'SI', label: 'Eslovenia' },
  { value: 'SB', label: 'Islas Salomón' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'Sudáfrica' },
  { value: 'GS', label: 'Georgia del Sur y las Islas Sandwich del Sur' },
  { value: 'ES', label: 'España' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudán' },
  { value: 'SR', label: 'Surinam' },
  { value: 'SJ', label: 'Svalbard y Jan Mayen' },
  { value: 'SZ', label: 'Suazilandia' },
  { value: 'SE', label: 'Suecia' },
  { value: 'CH', label: 'Suiza' },
  { value: 'SY', label: 'Siria' },
  { value: 'TW', label: 'Taiwán' },
  { value: 'TJ', label: 'Tayikistán' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'TH', label: 'Tailandia' },
  { value: 'TL', label: 'Timor Oriental' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad y Tobago' },
  { value: 'TN', label: 'Túnez' },
  { value: 'TR', label: 'Turquía' },
  { value: 'TM', label: 'Turkmenistán' },
  { value: 'TC', label: 'Islas Turcas y Caicos' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ucrania' },
  { value: 'AE', label: 'Emiratos Árabes Unidos' },
  { value: 'GB', label: 'Reino Unido' },
  { value: 'UM', label: 'Islas Ultramarinas Menores de Estados Unidos' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistán' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VG', label: 'Islas Vírgenes Británicas' },
  { value: 'VI', label: 'Islas Vírgenes de los Estados Unidos' },
  { value: 'WF', label: 'Wallis y Futuna' },
  { value: 'EH', label: 'Sáhara Occidental' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabue' },
]

// transformations:

/** Simple heuristic to convert an object to an Option (usage: ITEMS.map(toOption)) */
export const toOption = ({
  id,
  key,
  name,
  title,
  description,
  i18n,
  ...row
}: Record<string, unknown>): Option => {
  const value = (row?.value ?? id ?? key) as string
  let label = (row?.label ?? name ?? title ?? description) as string
  if (!label) {
    const { es = {}, en = {} } = (i18n ?? {}) as Record<string, Record<string, string>>
    if (es) label = (es.name ?? es.title ?? es.description ?? es.label ?? value) as string
    else if (en) label = (en.name ?? en.title ?? en.description ?? en.label ?? value) as string
  }
  // DISABLED: omit avatar and color for performance until @nuxt/ui-pro@v3 lands with fix
  // see https://github.com/nuxt/ui/issues/388 and https://github.com/nuxt/ui/issues/280
  const src = row?.image as string
  const avatar = src ? { src, alt: label } : undefined
  const color = row?.color as string
  return { value, label, avatar, color }
}

// avoids calling .find() for each row (O(n^2) -> O(n)) and uses a
// Map instead of a plain object (via .reduce()) for much better performance
export const toMapByKey = <T extends object>(array: T[], key: keyof T): Map<T[keyof T], T> => {
  const map = new Map<T[keyof T], T>()
  for (const item of array) {
    const value = get(item, key)
    if (value) map.set(value, item)
  }
  return map
}

export const toMapByGroupKey = <T extends object, K extends keyof T>(array: T[], key: K): Map<T[K], T[]> => {
  const map = new Map<T[K], T[]>()

  for (const item of array) {
    const value = item[key]

    if (value !== undefined && value !== null) {
      if (!map.has(value)) {
        map.set(value, [])
      }
      map.get(value)?.push(item)
    }
  }

  return map
}

// formatting:

export const formatAddress = (address: Address) => {
  return [
    [
      address?.street,
      address?.numberExterior,
      address?.numberInterior,
    ].filter(Boolean).join(', '),
    address?.neighborhood,
    [
      address?.postalCode ? `CP: ${address?.postalCode}` : '',
      address?.city,
      address?.district,
    ].filter(Boolean).join(', '),
    [
      address?.state,
      address?.country,
    ].filter(Boolean).join(', '),
  ].filter(Boolean).join('\n').trim()
}

export const formatDatetime = (isoString: string) => {
  if (!isoString) return ''
  return format(parseISO(isoString), 'yyyy-MM-dd\'T\'HH:mm')
}

export const formatDate = (isoString: string) => {
  if (!isoString) return ''
  return format(parseISO(isoString), 'yyyy-MM-dd')
}
